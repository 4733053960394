/******************************************
*  Author : Suraj Sharma   
*  Created On : Thu Apr 15 2021
*  File : Home.module.css
*******************************************/

.responsiveVideo {
  width: 100%;
}

.footerLinkContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: end;
}

.footerLinkItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.footerLinkText {
  font-size: 14px;
  font-weight: 500;
  color: #35625d;
  margin-left: 10px;
}

.separator {
  width: 1.5px;
  height: 16px;
  background-color: #35625d;
  margin: 0 10px;
}

.privacyContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
  margin-top: 10px;
  font-weight: bold;
}

.privacyContainer span {
  font-weight: 550 !important;
}

@media screen and (max-width: 1040px) and (min-width: 768px) {
  .responsiveVideo {
    height: 100vh;
  }
}

@media screen and (min-height: 880px) and (min-width: 760px) and (max-width: 3000px) {
  .responsiveVideo {
    min-height: 100vh !important;
    width: auto !important;
  }
}

@media (max-width: 1198px) {
  .privacyContainer {
    justify-content: center;
  }
}

@media (max-width: 991px) {
  .footerLinkText {
    font-size: 12px;
  }
  .privacyContainer {
    padding-left: 5px;
  }
}

@media (max-width: 880px) {
  .privacyContainer {
    justify-content: end;
  }
  .footerLinkContainer {
    width:100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .footer-button {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .footerLinkItem {
    justify-content: flex-end;
  }
}

@media (max-width: 767px) {
  .privacyContainer {
    justify-content: center;
  }
  .footerLinkContainer {
    flex-direction:column;
  }
  .footerLinkItem {
    margin-bottom: 10px;
    justify-content: center;
  }
  .separator {
    height: 15px;
  }
  .footerLinkText {
    font-size: 14px;
  }
}

@media (max-width: 510px) {
  .footerLinkItem {
    justify-content: center;
  }
}